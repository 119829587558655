.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1B1B1B;
}

.success-container h1 {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    color: #F7CA18;
}

.success-container p {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 0;
    color: #FFFFFF;
    text-shadow: 1px 1px #696969;
}

.cancel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #F5F5F5;
}

.cancel-container h1 {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    color: #FF5733;
}

.cancel-container p {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 0;
    color: #696969;
}